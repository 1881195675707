import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import './CreateAccount.css';

import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/AuthProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';

import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';

import { CREATE_ACCOUNT_TITLE, CREATE_ACCOUNT_SUBTITLE, SIGN_IN_URI, COMPANY_SETUP_URI } from 'src/contants';
import { NetworkProvider } from 'src/NetworkProvider';


function Onboarding() {

  const navigate = useNavigate();
  const [loadingText, setLoadingText] = useState(null);
  const { setLoginCredentials } = useContext(AuthContext);

  async function handleCreateAccount(formData, captchaToken) {

    try {
      await NetworkProvider.validateCaptchaToken(captchaToken)
    } catch (error) {
      alert("Failed to validate captcha ", error)
      return
    }

    const { name, surname, email, password } = formData;
    setLoadingText("Registering")
    NetworkProvider.insert_author(name, surname, email, password)
      .then(data => {
        NetworkProvider.acquire_token(email, password)
          .then(tokenData => {
            const { access_token, author_id } = tokenData;
            setLoginCredentials(access_token, author_id, null, email, password);

            setLoadingText(null)
            navigate(COMPANY_SETUP_URI, { state: { email: email, name: name, surname: surname } });
          })
          .catch(error => {
            setLoadingText(null)
            window.alert(error.detail || error);
          });
      })
      .catch(error => {
        setLoadingText(null)
        window.alert(error.detail || error);
      });
  }

  function handleSignIn() {
    navigate(SIGN_IN_URI);
  }

  function onFailedValidatingCaptcha(error) {
    setLoadingText(null);
    alert("Failed to validate captcha" + error)
  }

  return (
    <div className="split-screen-container">
      <Helmet>
        <script type="application/ld+json">
          {`
                {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "Create an account",
                    "description": "Create an account in the best influencer monetisation platform",
                    "url": "${window.location.href}"
                }
            `}
        </script>
      </Helmet>
      <div className="left-section">
        <div className="labels-container">
          <div className="textContainer">
            <label className="title-label">{CREATE_ACCOUNT_TITLE}</label>
            <label className="subtitle-label">{CREATE_ACCOUNT_SUBTITLE}</label>
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="title-text">Create an account</div>
        <UserTextFields
          onboardingState={OnboardingState.CREATE_ACCOUNT}
          onSubmit={handleCreateAccount}
          onValidatingCaptcha={() => setLoadingText("Validating captcha")}
          onFailedValidatingCaptcha={onFailedValidatingCaptcha}
        />
        <OnboardingPrompt
          onClick={handleSignIn}
          onboardingState={OnboardingState.SIGN_IN}
        />
      </div>
      <FullScreenLoader loadingText={loadingText} />
    </div>
  );
}

export default Onboarding;