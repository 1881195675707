import { makeStyles } from '@mui/styles';

const useOnboardingStyles = makeStyles({
    splitScreenContainer: {
        display: 'flex',
        height: '100vh',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        }
    },
    leftSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40%',
        backgroundColor: '#252525',
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },
    rightSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '60%',
        marginTop: '-50px',
        backgroundColor: '#fdfdfd',
        '@media (max-width: 600px)': {
            width: '100%',
            marginTop: '50px',
        },
    },
    labelsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        marginTop: '40%',
        '@media (max-width: 600px)': {
            marginTop: '5%',
        },
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '80px',
        paddingLeft: '40px',
        '@media (max-width: 600px)': {
            marginTop: '40px',
            paddingLeft: '0',
        },
    },
    titleLabel: {
        margin: '5px',
        fontSize: '40px',
        fontFamily: 'Inter',
        fontWeight: 700,
        color: '#FFFFFF',
        textAlign: 'left',
    },
    subtitleLabel: {
        margin: '5px',
        fontSize: '15px',
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#FFFFFF',
        textAlign: 'left',
        marginBottom: '20px',
    },
    titleText: {
        margin: '10px',
        fontSize: '29px',
        fontFamily: 'Inter',
        fontWeight: 600,
        marginBottom: '20px',
    },
});

export default useOnboardingStyles;
