import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@mui/styles';
import { FORGOT_PASSWORD_URI, CREATE_ACCOUNT_URI, ADMIN_URI, SUB_URI } from 'src/contants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';
import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums.jsx';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';
import colors from 'src/colors.json';
import trainers from 'src/trainers.json';
import usePaymentStyles from './PaymentStyles.js';

function SignInForTrainer() {
    const classes = usePaymentStyles();
    const navigate = useNavigate();
    const [loadingText, setLoadingText] = useState(null);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
    const [searchParams] = useSearchParams();

    const trainer = searchParams.get('trainer');
    const trainerColors = colors[trainer] || colors['default']
    const trainerInfo = trainers[trainer]
    const trainerName = trainerInfo["name"]

    let logoPath;

    try {
        logoPath = require(`src/Assets/Logos/${trainer}.png`);
    } catch (error) {
        console.error(`Logo not found for trainer: ${trainer}`, error);
    }

    async function handleSignIn(formData, captchaToken) {
        if (!captchaToken) {
            alert('Failed to validate captcha');
            return;
        }

        try {
            await NetworkProvider.validateCaptchaToken(captchaToken);
        } catch (error) {
            alert('Failed to validate captcha ', error);
            return;
        }

        const { email, password } = formData;

        try {
            setLoadingText('Logging in');
            const tokenData = await NetworkProvider.acquire_user_token(email, password, trainerInfo.id);
            const { access_token, user_id } = tokenData;

            if (!access_token) {
                setLoadingText(null);
                alert('Wrong credentials provided');
                return;
            }

            const credentials = {
                authToken: access_token,
                userId: user_id,
                email,
                password,
            };

            setLoginCredentials(access_token, trainerInfo.id, user_id, email, password);

            try {
                const userInfo = await NetworkProvider.getUserInfo(credentials, setLoginCredentials, user_id);

                const stateToPass = {
                    ...tokenData,
                    userInfo,
                };

                const subscriptionInfo = await NetworkProvider.get_user_sub_info(credentials, setLoginCredentials, trainer);


                if (subscriptionInfo.active == true) {
                    let adminUri = ADMIN_URI;
                    if (trainer) {
                        adminUri += "/?trainer=" + trainer
                    }
                    navigate(adminUri, { state: stateToPass });
                } else {
                    let subUri = SUB_URI;
                    if (trainer) {
                        subUri += "/?trainer=" + trainer
                    }
                    navigate(subUri, { state: stateToPass });
                }
                setLoadingText(null);
            } catch (error) {
                console.log(error);
                setLoadingText(null);
                alert(error.detail || error);
            }
        } catch (error) {
            setLoadingText(null);
            alert(error.detail || error);
        }
    }

    function handleSignup() {
        let createAccountUri = CREATE_ACCOUNT_URI;
        if (trainer) {
            createAccountUri += "?trainer=" + trainer
        }
        navigate(createAccountUri);
    }

    function handleForgotPassword() {
        let forgotPassUri = FORGOT_PASSWORD_URI;
        if (trainer) {
            forgotPassUri += "?trainer=" + trainer
        }
        navigate(forgotPassUri);
    }

    function onFailedValidatingCaptcha(error) {
        setLoadingText(null);
        alert('Failed to validate captcha ' + error);
    }

    return (
        <div className={classes.rootSquares}>
            <Helmet>
                <script type="application/ld+json">
                    {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Onboarding",
              "description": "Introduce Artificial intelligence power. Stay ahead of your competition with the best customer service 24/7.",
              "url": "${window.location.href}"
            }
          `}
                </script>
            </Helmet>
            <div className={classes.thirdShape}></div>
            <div className={classes.cardContainer}>
                <div className={classes.logoContainer}>
                    <img src={logoPath} alt="Logo" className={classes.logo} />
                    <span className={classes.logoText}>{trainerName}</span>
                </div>
                <div
                    className={classes.card}
                    style={{
                        backgroundColor: trainerColors?.primaryColor,
                        color: trainerColors?.textPrimaryColor
                    }}
                >
                    <div className={classes.titleText}>Sign in</div>
                    <UserTextFields
                        onboardingState={OnboardingState.SIGN_IN}
                        onSubmit={handleSignIn}
                        onValidatingCaptcha={() => setLoadingText('Validating captcha')}
                        onFailedValidatingCaptcha={onFailedValidatingCaptcha}
                        trainerColors={trainerColors}
                    />
                    <OnboardingPrompt
                        onClick={handleSignup}
                        onboardingState={OnboardingState.CREATE_ACCOUNT}
                        trainerColors={trainerColors}
                    />
                    <OnboardingPrompt
                        onClick={handleForgotPassword}
                        onboardingState={OnboardingState.FORGOT_PASSWORD}
                        trainerColors={trainerColors}
                    />
                </div>
            </div>
            <FullScreenLoader loadingText={loadingText} />
        </div>
    );
}

const useStyles = makeStyles({
    splitScreenContainer: {
        display: 'flex',
        height: '100vh',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },
    },
    leftSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40%',
        backgroundColor: '#252525',
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },
    rightSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '60%',
        marginTop: '-50px',
        backgroundColor: '#fdfdfd',
        '@media (max-width: 600px)': {
            width: '100%',
            marginTop: '50px',
        },
    },
    labelsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        marginTop: '40%',
        '@media (max-width: 600px)': {
            marginTop: '5%',
        },
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '80px',
        paddingLeft: '40px',
        '@media (max-width: 600px)': {
            marginTop: '40px',
            paddingLeft: '0',
        },
    },
    titleLabel: {
        margin: '5px',
        fontSize: '40px',
        fontFamily: 'Inter',
        fontWeight: 700,
        color: '#FFFFFF',
        textAlign: 'left',
    },
    subtitleLabel: {
        margin: '5px',
        fontSize: '15px',
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#FFFFFF',
        textAlign: 'left',
        marginBottom: '20px',
    },
    titleText: {
        margin: '10px',
        fontSize: '29px',
        fontFamily: 'Inter',
        fontWeight: 600,
        marginBottom: '20px',
    },
});

export default SignInForTrainer;
