import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FORGOT_PASSWORD_TITLE, FORGOT_PASSWORD_SUBTITLE, SIGN_IN_URI } from 'src/contants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';
import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';
import colors from 'src/colors.json';
import useOnboardingStyles from '../OnboardingStyles';

function ForgotPassword() {
    const classes = useOnboardingStyles();
    const navigate = useNavigate();
    const [loadingText, setLoadingText] = useState(null);

    const [searchParams] = useSearchParams();

    const trainer = searchParams.get('trainer');
    const trainerColors = colors[trainer] || colors['default']

    async function handleForgotPassword(formData, captchaToken) {
        if (!captchaToken) {
            alert('Failed to validate captcha');
            return;
        }

        try {
            await NetworkProvider.validateCaptchaToken(captchaToken);
        } catch (error) {
            alert('Failed to validate captcha ', error);
            return;
        }

        setLoadingText('Sending reset link');

        try {
            const { email } = formData;
            await NetworkProvider.send_password_reset_link(email);
            alert('Password reset email will be sent if such email is found in our system');
        } catch (error) {
            alert('Error sending password reset link');
        }

        setLoadingText(null);
        handleSignIn();
    }

    function handleSignIn() {
        let signInUri = SIGN_IN_URI;
        if (trainer) {
            signInUri += "/?trainer=" + trainer
        }
        navigate(signInUri);
    }

    function onFailedValidatingCaptcha(error) {
        setLoadingText(null);
        alert('Failed to validate captcha: ' + error);
    }

    return (
        <div
            className={classes.splitScreenContainer}
            style={{
                backgroundColor: trainerColors?.primaryColor,
                color: trainerColors?.textPrimaryColor
            }}
        >
            <Helmet>
                <script type="application/ld+json">
                    {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Forgot Password",
            "description": "Reset your password with ease.",
            "url": "${window.location.href}"
          }
          `}
                </script>
            </Helmet>
            <div className={classes.leftSection}>
                <div className={classes.labelsContainer}>
                    <div className={classes.textContainer}>
                        <label className={classes.titleLabel}>{FORGOT_PASSWORD_TITLE}</label>
                        <label className={classes.subtitleLabel}>{FORGOT_PASSWORD_SUBTITLE}</label>
                    </div>
                </div>
            </div>
            <div
                className={classes.rightSection}
                style={{
                    backgroundColor: trainerColors?.primaryColor,
                    color: trainerColors?.textPrimaryColor
                }}
            >
                <div className={classes.titleText}>Forgot Password</div>
                <UserTextFields
                    onboardingState={OnboardingState.FORGOT_PASSWORD}
                    onSubmit={handleForgotPassword}
                    onValidatingCaptcha={() => setLoadingText('Validating captcha')}
                    onFailedValidatingCaptcha={onFailedValidatingCaptcha}
                    trainerColors={trainerColors}
                />
                <OnboardingPrompt
                    onClick={handleSignIn}
                    onboardingState={OnboardingState.SIGN_IN}
                    trainerColors={trainerColors}
                />
            </div>
            <FullScreenLoader loadingText={loadingText} />
        </div>
    );
}

export default ForgotPassword;
