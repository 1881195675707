import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FORGOT_PASSWORD_URI } from 'src/contants';
import './SignIn.css';

import { useNavigate } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';

import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';

import { SIGN_IN_TITLE, SIGN_IN_SUBTITLE, CREATE_ACCOUNT_URI, COMPANY_SETUP_URI, ADMIN_URI } from 'src/contants';


function SignIn() {

    const navigate = useNavigate();
    const [loadingText, setLoadingText] = useState(null);
    const { setLoginCredentials } = useContext(AuthContext);

    async function handleSignIn(formData, captchaToken) {

        if (captchaToken == null) {
            alert("Failed to validate captcha")
            return;
        }

        try {
            await NetworkProvider.validateCaptchaToken(captchaToken)
        } catch (error) {
            alert("Failed to validate captcha ", error)
            return
        }

        const { email, password } = formData;

        try {
            setLoadingText("Loging in")
            const tokenData = await NetworkProvider.acquire_token(email, password);
            const { access_token, author_id } = tokenData;

            if (access_token == null) {
                setLoadingText(null)
                window.alert("Wrong credentials provided");
                return;
            }

            const credentials = {
                authToken: access_token,
                authorId: author_id,
                email,
                password
            }

            setLoginCredentials(access_token, author_id, null, email, password)

            try {
                const authorData = await NetworkProvider.get_author(credentials, setLoginCredentials);
                const stateToPass = {
                    ...tokenData,
                    authorData
                };

                if (authorData.customerId != null) {
                    navigate(ADMIN_URI, { state: stateToPass });
                } else {
                    navigate(COMPANY_SETUP_URI, { state: stateToPass });
                }
                setLoadingText(null)
            } catch (error) {
                setLoadingText(null)
                window.alert(error.detail || error);
            }
        } catch (error) {
            setLoadingText(null)
            window.alert(error.detail || error);
        }
    }


    function handleSignup() {
        navigate(CREATE_ACCOUNT_URI);
    }

    function handleForgotPassword() {
        navigate(FORGOT_PASSWORD_URI);
    }

    function onFailedValidatingCaptcha(error) {
        setLoadingText(null);
        alert("Failed to validate captcha" + error)
    }

    return (
        <div className="split-screen-container">
            <Helmet>
                <script type="application/ld+json">
                    {`
                {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "Onboarding",
                    "description": "Introduce Artificial intelligence power. Stay ahead of your competition with the best customer service 24/7.",
                    "url": "${window.location.href}"
                }
            `}
                </script>
            </Helmet>
            <div className="left-section">
                <div className="labels-container">
                    <div className="textContainer">
                        <label className="title-label">{SIGN_IN_TITLE}</label>
                        <label className="subtitle-label">{SIGN_IN_SUBTITLE}</label>
                    </div>
                </div>
            </div>
            <div className="right-section">
                <div className="title-text">Sign in</div>
                <UserTextFields
                    onboardingState={OnboardingState.SIGN_IN}
                    onSubmit={handleSignIn}
                    onValidatingCaptcha={() => setLoadingText("Validating captcha")}
                    onFailedValidatingCaptcha={onFailedValidatingCaptcha}
                />
                <OnboardingPrompt
                    onClick={handleSignup}
                    onboardingState={OnboardingState.CREATE_ACCOUNT}
                />
                <OnboardingPrompt
                    onClick={handleForgotPassword}
                    onboardingState={OnboardingState.FORGOT_PASSWORD}
                />
            </div>
            <FullScreenLoader loadingText={loadingText} />
        </div>
    );
}

export default SignIn;