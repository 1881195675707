import React, { useState, useEffect, useContext } from 'react';
import BlueButton from 'src/Elements/BlueButton';
import EditableCode from './EditableCode';
import { makeStyles } from '@material-ui/core/styles';
import { CategoriesContext } from '../Categories/CategoriesContext';
import VipPlans from './VipPlans';
import VIPCalendar from './VipCalendar';
import CreateVIPCodeDialog from './CreateVIPCodeDialog';

const StateEnum = {
    MAIN: 0,
    VIP_PLANS: 1,
    VIP_CALENDAR: 2
};

const VipTraining = ({ setLoadingText }) => {

    const classes = useStyles();
    const [state, setState] = useState(StateEnum.MAIN);
    const [selectedVipCode, setSelectedVipCode] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const { vipCodes, fetchVipCodes, addVIPCode, updateVIPCodeCategories, deleteVIPCode } = useContext(CategoriesContext);
    const [isCrateVIPCodeDialogOpen, setIsCrateVIPCodeDialogOpen] = useState(false);

    useEffect(() => {
        if (vipCodes == null) {
            handleLoadCodes()
        }
    }, []);

    useEffect(() => {
        if (selectedVipCode) {
            const matchingVipCode = vipCodes.find(vipCode => vipCode.code === selectedVipCode.code);

            if (matchingVipCode) {
                const categories = JSON.parse(matchingVipCode.categories);
                setSelectedVipCode(matchingVipCode);
            }
        }
    }, [vipCodes]);

    const handleLoadCodes = async () => {
        try {
            setLoadingText("Fetching codes");
            await fetchVipCodes();
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
            setLoadingText(null)
        }
    };

    const onAddNewCode = async (name) => {
        try {
            setLoadingText("Adding Vip Code");
            await addVIPCode(name);
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
            setLoadingText(null)
        }
    }

    const handleCodeClick = (vipCode) => {
        setSelectedVipCode(vipCode)
        setState(StateEnum.VIP_PLANS);
    }

    const handleCodeDelete = async (vipCode) => {
        try {
            setLoadingText("Deleting VIP Code");
            await deleteVIPCode(vipCode)
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
            setLoadingText(null)
        }
    }

    const handleCodeEdit = (vipCode) => {
        setSelectedVipCode(vipCode)
        setState(StateEnum.VIP_PLANS);
    }

    const onCloseVipPlans = () => {
        setSelectedVipCode(null);
        setState(StateEnum.MAIN);
    }

    const onCloseVipCalendar = () => {
        setSelectedPlan(null);
        setState(StateEnum.VIP_PLANS);
    }

    const handleVIPCodeUpdated = async (vipCode, category) => {
        try {
            await updateVIPCodeCategories(vipCode, category);
        } catch (error) {
            window.alert(error)
        }
    }

    const onPlanClick = (plan) => {
        setSelectedPlan(plan)
        setState(StateEnum.VIP_CALENDAR);
    }

    const VipCodesListContent = () => {

        if (vipCodes == null) {
            return null;
        }
    
        if (vipCodes.length === 0) {
            return (
                <div className={classes.addCategoryWrapper}>
                    <div className={classes.addCategoryContainer}>
                        <h3 className={classes.exampleTitle}>Let's add your first VIP user. Each VIP user has their own training plans.</h3>
                        <BlueButton onClick={() => setIsCrateVIPCodeDialogOpen(true)}>Add code</BlueButton>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h2 className={classes.mainTitle}>VIP Codes</h2>
                    <header className={classes.header}>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={() => setIsCrateVIPCodeDialogOpen(true)}>Add code</BlueButton>
                        </div>
                    </header>
    
                    <h2 className={classes.categoriesTitle}>VIP Codes</h2>
                    <div className={classes.tableHeader}>
                        <div className={classes.headerCell}>Code</div>
                        <div className={classes.headerCell}>Code name</div>
                        <div className={classes.headerCell}>Active user</div>
                    </div>
    
                    <div className={classes.contentArea}>
                        <div className={classes.categoryList}>
                            {vipCodes.map((code) => (
                                <EditableCode
                                    key={code.code}
                                    code={code}
                                    onCodeClick={handleCodeClick}
                                    onDeleteClick={handleCodeDelete}
                                    onEditClick={handleCodeEdit}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            );
        }
    };
    

    return (
        <React.Fragment>
            {state === StateEnum.MAIN &&
                <>
                    <VipCodesListContent />
                    <CreateVIPCodeDialog
                        open={isCrateVIPCodeDialogOpen}
                        onClose={() => setIsCrateVIPCodeDialogOpen(false)}
                        title="Dialog Title"
                        onValueSubmit={onAddNewCode}
                    />
                </>}
            {state === StateEnum.VIP_PLANS && selectedVipCode && <VipPlans
                vipcode={selectedVipCode}
                onClose={onCloseVipPlans}
                onPlanClick={onPlanClick}
                setLoadingText={setLoadingText}
                onVipCodeUpdated={handleVIPCodeUpdated}
            />}
            {state === StateEnum.VIP_CALENDAR && selectedPlan && <VIPCalendar
                vipcode={selectedVipCode}
                plan={selectedPlan}
                onVipCodeUpdated={handleVIPCodeUpdated}
                setLoadingText={setLoadingText}
                onClose={onCloseVipCalendar}
            />}
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000'
    },
    categoriesTitle: {
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    categoryList: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: '100%',
        boxSizing: 'border-box',
    },
    header: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 100,
    },
    tableHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: '#e0e0e0',
        borderRadius: 10,
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    headerCell: {
        flex: 1, // Align each cell with the corresponding data
        textAlign: 'left',
        boxSizing: 'border-box',
    },
    headerButton: {
        marginLeft: 'auto',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    categoriesExampleIcon2: {
        maxWidth: 300,
    },
    addCategoryWrapper: {
        width: '100%',
        height: '100%'
    },
    addCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '300px',
        margin: '0 auto',
    }
});

export default VipTraining;
