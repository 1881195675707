import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WhiteButton from 'src/Elements/WhiteButton';
import { Container } from '@material-ui/core';
import WhiteTextField from 'src/Elements/WhiteTextField';
import BlueBorderButton from 'src/Elements/BlueBorderButton';
import BlueTransparentButton from 'src/Elements/BlueTransparentButton';
import BlueButton from 'src/Elements/BlueButton';
import ExerciseVideoThumbnail from './ExerciseVideoThumbnail';
import DeleteButton from 'src/Elements/Deletebutton';
import { AuthContext } from 'src/AuthProvider';
import { NetworkProvider } from 'src/NetworkProvider';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import { MAX_VIDEO_SIZE_MB, MAX_VIDEO_DURATION_SECONDS } from 'src/contants';
import { generateUniqueId } from 'src/Utilities/Utilities';
import { CategoriesContext } from '../../CategoriesContext';

const Exercises = ({
    day,
    plan,
    planId,
    onDayUpdated,
    onDoneClick,
    onAddExistingExerciseClick,
    setLoadingText,
    useCategoriesNetwork,
    canPickExisting = false
}) => {
    const classes = useStyles();
    const [localExercises, setLocalExercises] = useState([]);
    const inputFileRef = useRef();
    const [currentExerciseId, setCurrentExerciseId] = useState(null);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
    const { deleteExercise } = useContext(CategoriesContext);

    useEffect(() => {
        if (day.exercises) {
            setLocalExercises(day.exercises);
        }
    }, [day]);

    const onVideoUploadProgress = (percentage) => {
        setLoadingText("Uploading video " + percentage + "%")
    }

    const handleVideoUpload = async (videoFile) => {

        if (!currentExerciseId) {
            return;
        }

        // Check file size in bytes
        const maxSizeBytes = MAX_VIDEO_SIZE_MB * 1024 * 1024;
        if (videoFile.size > maxSizeBytes) {
            alert("The selected video exceeds the maximum size limit of " + MAX_VIDEO_SIZE_MB + "MB");
            return;
        }

        // Check duration by loading metadata
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.src = URL.createObjectURL(videoFile);
        document.body.appendChild(video);
        const metadataLoaded = new Promise((resolve) => {
            video.onloadedmetadata = () => resolve();
        });
        await metadataLoaded;
        if (Math.ceil(video.duration) >= MAX_VIDEO_DURATION_SECONDS) {
            alert("The selected video exceeds the maximum duration limit of " + MAX_VIDEO_DURATION_SECONDS + " seconds");
            return;
        }

        try {
            setLoadingText("Starting video upload...")
            const videoResponse = await NetworkProvider.upload_video(loginCredentials, setLoginCredentials, videoFile, onVideoUploadProgress);

            const updatedExercises = localExercises.map((exercise) => {
                setLoadingText(null)
                if (exercise.id === currentExerciseId) {
                    return {
                        ...exercise,
                        video: videoResponse,
                    };
                }
                return exercise;
            });

            // Delete previous video
            const previousVideo = localExercises.find(exercise => exercise.id === currentExerciseId)?.video;
            if (previousVideo && previousVideo != "") {

                const videoCounts = CategoriesReducer.extractVideoCountsFromPlan(plan);
                try {
                    const video = videoCounts[previousVideo] === 1 ? previousVideo : null;
                    if (video) {
                        NetworkProvider.delete_video(loginCredentials, setLoginCredentials, previousVideo)
                    }
                } catch { }
            }

            let videoName;
            const indexOfLastDot = videoResponse.lastIndexOf(".");
            if (indexOfLastDot === -1) {
                videoName = videoResponse
            } else {
                videoName = videoResponse.substring(0, indexOfLastDot);
            }
            setLoadingText("Creating thumbnail...")
            await createThumbnail(videoFile, videoName, updatedExercises)
            setLoadingText(null)

        } catch (error) {
            setLoadingText(null)
            alert("Error uploading video: " + error);
        }
    };

    const createThumbnail = async (file, fileName, updatedExercises) => {
        const video = document.createElement('video');
        const url = URL.createObjectURL(file);
        video.src = url;
        let captureFrameCalled = false;

        const captureFrame = () => {
            if (captureFrameCalled) {
                const existingCanvas = document.querySelector('canvas');
                if (existingCanvas) {
                    existingCanvas.remove();
                }

                const existingVideo = document.querySelector('video');
                if (existingVideo) {
                    existingVideo.remove();
                }
                return;
            }

            captureFrameCalled = true;
            video.removeEventListener('seeked', captureFrame);
            const canvas = document.createElement('canvas');
            const maxDimension = 512;

            // Calculate the scaling factor to maintain aspect ratio
            const scale = Math.min(maxDimension / video.videoWidth, maxDimension / video.videoHeight);

            canvas.width = video.videoWidth * scale;
            canvas.height = video.videoHeight * scale;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(async (blob) => {
                if (blob) {
                    updateExercises(updatedExercises);
                    setCurrentExerciseId(null);
                    setLoadingText(null)

                    const existingCanvas = document.querySelector('canvas');
                    if (existingCanvas) {
                        existingCanvas.remove();
                    }

                    const existingVideo = document.querySelector('video');
                    if (existingVideo) {
                        existingVideo.remove();
                    }
                }
            }, 'image/jpeg');
        };

        video.addEventListener('canplaythrough', () => {
            video.currentTime = 1;
        });

        video.addEventListener('seeked', captureFrame);
        video.load();
    };


    const onVideUploadClick = (exerciseId) => {
        setCurrentExerciseId(exerciseId);
        inputFileRef.current.click();
    }

    const addExercise = async () => {
        try {
            if (useCategoriesNetwork) {
                const newExercise = {
                    index: localExercises.length,
                    name: '',
                    description: '',
                    image: '',
                    video: '',
                    progress: 0,
                    dayId: day.id
                };

                const responseData = await NetworkProvider.add_exercise(loginCredentials, setLoginCredentials, newExercise, day.weekId, planId, day.id);
                updateExercises([...localExercises, responseData]);
            } else {
                const localExercise = {
                    index: localExercises.length,
                    name: '',
                    description: '',
                    image: '',
                    video: '',
                    progress: 0,
                    dayId: day.id,
                    id: generateUniqueId()
                };
                updateExercises([...localExercises, localExercise]);
            }
        } catch (error) {
            setLoadingText(null)
            alert(error);
        }

    };

    const addRest = async () => {
        try {
            if (useCategoriesNetwork) {
                setLoadingText("Adding exercise")
                const newRest = {
                    index: localExercises.length,
                    name: 'Rest',
                    description: '',
                    image: '',
                    video: '',
                    progress: 0,
                    amount: 10,
                };

                const responseData = await NetworkProvider.add_exercise(loginCredentials, setLoginCredentials, newRest, day.weekId, planId, day.id);
                updateExercises([...localExercises, responseData]);
            } else {
                const newRest = {
                    index: localExercises.length,
                    name: 'Rest',
                    description: '',
                    image: '',
                    video: '',
                    progress: 0,
                    amount: 10,
                    id: generateUniqueId()
                };
                updateExercises([...localExercises, newRest]);
            }
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            alert(error);
        }
    };

    const handleExerciseNameChange = (index, newName) => {
        const updatedExercises = [...localExercises];
        updatedExercises[index].name = newName;
        updateExercises(updatedExercises);
    };

    const handleExerciseDescriptionChange = (index, newDescription) => {
        const updatedExercises = [...localExercises];
        updatedExercises[index].description = newDescription;
        updateExercises(updatedExercises);
    };

    const handleRestAmountChange = (index, newAmount) => {
        const updatedExercises = [...localExercises];
        updatedExercises[index].amount = newAmount;
        updateExercises(updatedExercises);
    };

    const onDeleteExercise = async (index) => {
        try {
            const exercise = localExercises[index];

            setLoadingText("Deleting exercise...")
            await deleteExercise(exercise);

            if (useCategoriesNetwork) {
                await NetworkProvider.delete_exercise(loginCredentials, setLoginCredentials, exercise.id);
            }
            const updatedExercises = [...localExercises];
            updatedExercises.splice(index, 1);
            updateExercises(updatedExercises);
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            alert(error);
        }
    }

    const updateExercises = (newExercises) => {
        var updatedDay = day;
        updatedDay.exercises = newExercises;
        setLocalExercises(newExercises)
        onDayUpdated(updatedDay);
    }

    const exerciseCell = (exercise, index) => {

        const currentExerciseId = exercise.id;

        return (
            <div key={currentExerciseId} className={classes.exerciseLineContent}>
                <div className={classes.excerciseFieldTitle}>{"Exercise name"}</div>
                <WhiteTextField
                    fullWidth
                    value={exercise.name}
                    placeholder={"Enter exercise name"}
                    onChange={(e) => handleExerciseNameChange(index, e.target.value)}
                />
                <div className={classes.spacer}></div>
                <div className={classes.excerciseFieldTitle}>{"Exercise description"}</div>
                <WhiteTextField
                    fullWidth
                    value={exercise.description}
                    placeholder={"Enter exercise description"}
                    onChange={(e) => handleExerciseDescriptionChange(index, e.target.value)}
                />
                <div className={classes.videoContainer}>
                    <Container key={exercise.id} className={classes.videoUploadContainer}>
                        {exercise.video && (
                            <div className={classes.thumbnailContainer}>
                                <ExerciseVideoThumbnail
                                    key={exercise.id}
                                    fileName={exercise.video}
                                    authorId={loginCredentials.authorId}
                                />
                            </div>
                        )}
                        <p className={classes.imageUploadTitle}>Exercise video</p>
                        <div className={classes.uploadButtonContainer}>
                            <input
                                type="file"
                                accept="video/*"
                                onChange={(e) =>
                                    handleVideoUpload(e.target.files[0])
                                }
                                ref={inputFileRef}
                                style={{ display: 'none' }}
                            />
                            <WhiteButton onClick={() => onVideUploadClick(currentExerciseId)}>Upload</WhiteButton>
                        </div>
                    </Container>
                    <div className={classes.deleteWrapper}>
                        <div className={classes.deleteContainer}>
                            <DeleteButton onClick={() => onDeleteExercise(index)} />
                        </div>
                    </div>
                </div>
            </div>)
    }

    const restCell = (exercise, index) => (
        <div key={exercise.id} className={classes.exerciseLineContent}>
            <Container className={classes.restContainer}>
                <div className={classes.restTitle}>
                    😴 Rest
                </div>
            </Container>
            <div className={classes.spacer}></div>
            <div className={classes.excerciseFieldTitle}>{"Amount (Minutes)"}</div>
            <WhiteTextField
                fullWidth
                value={exercise.amount}
                placeholder={"Enter exercise description"}
                onChange={(e) => handleRestAmountChange(index, e.target.value)}
            />
            <div className={classes.deleteWrapper}>
                <div className={classes.deleteContainer}>
                    <DeleteButton onClick={() => onDeleteExercise(index)} />
                </div>
            </div>
        </div>
    )

    const bottomButtonContainer = () => (
        <div className={classes.bottomButtonWrapper}>
            <div className={canPickExisting ? classes.threeButtonContainer : classes.twoButtonContainer}>
                <BlueTransparentButton onClick={addExercise}> Add exercise</BlueTransparentButton>
                { canPickExisting && <BlueTransparentButton onClick={onAddExistingExerciseClick}> Add existing exercise</BlueTransparentButton>}
                <BlueBorderButton onClick={addRest}> Add rest</BlueBorderButton>
            </div>
        </div>
    )

    const doneButtonContainer = () => (
        <div className={classes.doneButtonContainer}>
            <BlueButton onClick={onDoneClick}>Done</BlueButton>
        </div>
    )

    return (
        <div className={classes.container}>
            {localExercises.length === 0 && bottomButtonContainer()}
            {localExercises.map((exercise, index) => (
                <div key={exercise.id} className={classes.exerciseContainer}>
                    {exercise.name === "Rest" ? (
                        restCell(exercise, index)
                    ) : (
                        exerciseCell(exercise, index)
                    )}
                    {index === localExercises.length - 1 && bottomButtonContainer()}
                </div>
            ))}

            {doneButtonContainer()}
        </div>
    );
};

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: '#E4E9ED'
    },
    exerciseContainer: {
        borderRadius: '4px',
        margin: '10px',
        paddingBottom: '10px'
    },
    exerciseLineContent: {
        borderLeft: '2px solid #9CB6FB',
        padding: '10px',
        margin: '20px',
    },
    excerciseFieldTitle: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
        color: '#425466',
        marginBottom: '3px'
    },
    spacer: {
        height: '20px'
    },
    videoUploadContainer: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: 20
    },
    restTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%'
    },
    restContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        height: '90px'
    },
    imageUploadTitle: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        color: '#16192C',
    },
    uploadButtonContainer: {
        width: '100px'
    },
    videoContainer: {
        marginTop: '50px'
    },
    bottomButtonWrapper: {
        margin: '20px 20px'
    },
    threeButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '10px',
        '& > *': {
            flex: '0 0 30%',
        },
    },
    twoButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '10px',
        '& > *': {
            flex: '0 0 48%',
        },
    },
    doneButtonContainer: {
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: '#E4E9ED',
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
    thumbnailContainer: {
        width: 75,
        height: 62,
        borderRadius: '8px',
        overflow: 'hidden',
    },
    deleteWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        marginTop: '10px'
    },
    deleteContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
    },
    deleteIcon: {
        marginRight: 5,
        color: '#DF2E2E'
    },
    deleteText: {
        textAlign: 'center',
        color: '#DF2E2E',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
    },
}));

export default Exercises;
