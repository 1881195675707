import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { NetworkProvider } from 'src/NetworkProvider';
import invoicesSectionIcon from "../../../Assets/invoices-section-icon.svg";
import { AuthContext } from 'src/AuthProvider';
import BlueButton from 'src/Elements/BlueButton';

import colors from 'src/colors.json';
import trainers from 'src/trainers.json';

function MyInvoices({ trainer }) {
  const classes = useStyles();
  const [invoices, setInvoices] = useState([]);
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

  const trainerColors = colors[trainer] || colors['default']

  useEffect(() => {
    listInvoices();
  }, []);

  const listInvoices = async () => {
    try {
      let data;
      if (trainer) {
        data = await NetworkProvider.list_user_invoices(loginCredentials, setLoginCredentials, trainer);
      } else {
        data = await NetworkProvider.list_invoices(loginCredentials, setLoginCredentials);
      }
      setInvoices(data.data);
    } catch (error) {
      window.alert(error);
    }
  };

  return (
    <div className={classes.invoiceContainer}>
      <div
        className="PageName"
        style={{ color: trainerColors?.textPrimaryColor }}
      >
        My Invoices
      </div>
      {invoices.length === 0 ? (
        <p>Fetching invoices</p>
      ) : (
        <table className={classes.tableDesign}>
          <thead>
            <tr>
              <th className={classes.tableHeader} style={{color: trainerColors.textSecondaryColor}}>Invoice</th>
              <th className={classes.tableHeader} style={{color: trainerColors.textSecondaryColor}}>Date Created</th>
              <th className={classes.tableHeader} style={{color: trainerColors.textSecondaryColor}}>Amount</th>
              <th className={classes.tableHeader} style={{color: trainerColors.textSecondaryColor}}>State</th>
              <th className={classes.tableHeader} style={{color: trainerColors.textSecondaryColor}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td className={classes.tableCell} style={{ color: trainerColors?.textPrimaryColor }}>
                <div className={classes.nameCell}>
                  <img
                    src={invoicesSectionIcon}
                    alt="Done Step image"
                    className={classes.cellImage}
                  />
                  {invoice.customer_name}</div>
                </td>
                <td className={classes.tableCell} style={{ color: trainerColors?.textSecondaryColor }}>
                  {new Date(invoice.created * 1000).toLocaleDateString()}
                </td>
                <td className={classes.tableCell} style={{ color: trainerColors?.textSecondaryColor }}>
                  {(invoice.amount_due / 100).toFixed(2)} {invoice.currency.toUpperCase()}
                </td>
                <td className={classes.tableCell}>
                  <label className={classes.statusLabel}>{invoice.status}</label>
                </td>
                <td className={classes.tableCell}>
                  <a
                    href={invoice.hosted_invoice_url}
                    className={classes.linkUnderline}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={classes.invoiceButtonDesign}>
                      <BlueButton style={{ backgroundColor: trainerColors?.accentColor }}>View Invoice</BlueButton>
                    </div>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  invoiceContainer: {
    marginTop: 40,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableDesign: {
    width: '100%',
    maxWidth: 1240,
    borderCollapse: 'collapse',
  },
  tableHeader: {
    textAlign: 'left',
    fontSize: 13,
    padding: '10px 5px',
    borderBottom: '1px solid #e3e3e3',
  },
  tableCell: {
    padding: '10px 5px',
    borderBottom: '1px solid #e3e3e3',
    justifyContent: 'center',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cellImage: {
    marginRight: 15,
  },
  statusLabel: {
    color: '#2CAC74',
    padding: '4px 10px',
    backgroundColor: '#DEFFEE',
    fontWeight: 700,
    borderRadius: 5,
    textTransform: 'capitalize',
    fontSize: 14,
  },
  linkUnderline: {
    textDecoration: 'none',
  },
  invoiceButtonDesign: {
    width: 150,
  },
});

export default MyInvoices;
