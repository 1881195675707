import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const BlueButton = styled(Button)({
    width: '100% !important',
    backgroundColor: '#3F6EE8',
    color: 'white !important',
    padding: '10px 20px !important',
    borderRadius: '8px !important',
    cursor: 'pointer !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    fontFamily: 'Inter !important',
    '&:hover': {
        backgroundColor: '#305dcf',
    },
});

  export default BlueButton;