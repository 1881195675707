import { makeStyles } from '@mui/styles';

const usePaymentStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(170deg, #F7FAFC 40%, white 40%)'
    },
    rootSquares: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        position: 'relative', // Required for absolutely positioned child elements
        overflow: 'hidden',   // Ensures shapes don't spill outside the viewport
        background: 'linear-gradient(170deg, #F7FAFC 40%, white 40%)',
        zIndex: 0,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            zIndex: 0, // Place behind the card
        },
        '&::before': {
            width: '300px',
            height: '50px',
            backgroundColor: '#0C00FA80', // Purple shape color
            transform: 'skew(-10deg) rotate(170deg)', // Add rotation
            top: '40%', // Position of the upper shape
            left: '-50px', // Adjust positioning horizontally
            opacity: 0.3
        },
        '&::after': {
            width: '300px', // Match the width of the upper shape
            height: '30px',
            backgroundColor: '#81E9FF', // Light blue shape color
            transform: 'skew(-10deg) rotate(170deg)', // Add rotation to match the upper shape
            top: '45%', // Adjust positioning to align below the upper shape
            left: '-30px', // Slightly shift horizontally for alignment
        }
    },
    thirdShape: {
        position: 'absolute',
        width: '250px',
        height: '40px',
        backgroundColor: '#0C00FA80', // Orange color for distinction
        transform: 'skew(-10deg) rotate(170deg)', // Same orientation
        top: '25%', // Position at the top
        right: '0px', // Position towards the right
        opacity: 0.3, // Slightly less transparent
        zIndex: 0, // Behind the card
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 2,
        maxWidth: '542px',
        minWidth: '90%',
        marginLeft: '10px',
        marginRight: '10px'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.5rem',
        marginLeft: '10px',
    },
    logo: {
        width: '50px',
        height: '50px',
        borderRadius: '12px',
        marginRight: '0.5rem',
    },
    logoText: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh',
        maxWidth: '542px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        zIndex: 1,
        width: '100%',
    },
    titleText: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        textAlign: 'center',
    },
    content: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#F7FAFC'
    },
    container: {
        width: "90%",
        maxWidth: "600px",
        overflow: "hidden",
    },
    logoSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px"
    },
    logo: {
        width: "50px",
        height: "50px",
        marginRight: "10px",
        borderRadius: '8px'
    },
    logoText: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#333",
    },
    innerContent: {
        padding: "20px"
    },
    subscribeButton: {
        marginTop: "20px",
        width: "100%",
    },
    stripeFooter: {
        textAlign: "center",
    },
    stripeLogo: {
        marginLeft: "5px",
        height: "20px",
        height: '30px'
    },
    spacer: {
        height: '10px'
    },
    separatorSpacer: {
        height: '30px'
    },
    separator: {
        height: 1,
        opacity: 0.3,
        backgroundColor: 'gray'
    }

});

export default usePaymentStyles;
