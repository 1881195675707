export const API_URL = process.env.REACT_APP_API_URL;
export const STRIPE_PK_KEY = process.env.REACT_APP_STRIPE_PK_KEY;
export const HOMEPAGE_URL = process.env.REACT_APP_HOMEPAGE_URL;
export const SUBSCRIPTION_ID = process.env.REACT_APP_SUBSCRIPTION_ID;
export const DEFAULT_LOGIN = process.env.REACT_APP_DEFAULT_LOGIN;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const USER_PURCHASE_SUCCESS_URI = process.env.REACT_APP_USER_PURCHASE_SUCCESS_URI;
export const USER_PURCHASE_FAIL_URI = process.env.REACT_APP_USER_PURCHASE_FAIL_URI;

export const CREATE_ACCOUNT_URI = "/"
export const SIGN_IN_URI = "/signin"
export const FORGOT_PASSWORD_URI = "/forgot"
export const ADMIN_URI = "/panel"
export const COMPANY_SETUP_URI = "/companysetup"
export const PURCHASE_SUCCESS_URI = "/purchasesuccess"
export const RESET_PASSWORD_URI = "/reset"
export const SUB_URI = "/sub"
export const USER_PAYMENT_SUCCESS_URI = "/success"

export const GOOGLE_CAPTCHA_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;


export const CREATE_ACCOUNT_TITLE = "Is this your first time here?";
export const CREATE_ACCOUNT_SUBTITLE = "Create a new account";

export const SIGN_IN_TITLE = "Already have an account with us?";
export const FORGOT_PASSWORD_TITLE = "Send password reset link";
export const FORGOT_PASSWORD_SUBTITLE = "Enter your email for which we will send the password reset link if the email is found in our system";
export const RESET_PASSWORD_TITLE = "Reset password";
export const RESET_PASSWORD_SUBTITLE = "Reset password link will be active for 15 minutes";
export const SIGN_IN_SUBTITLE = "Sign in with email and password";

export const MAX__WEEKS_IN_PLAN = 12;
export const MAX_INGREDIENTS_IN_RECIPE = 30;
export const MAX_COOKING_METHODS_IN_RECIPE = 30;
export const MAX_VIDEO_SIZE_MB = 100;
export const MAX_VIDEO_DURATION_SECONDS = 42;
export const MAX_DAILY_VIDEO_SIZE_MB = 300;
export const MAX_DAILY_VIDEO_DURATION_SECONDS = 180;
export const MAX_GREETING_VIDEO_SIZE_MB = 150;
export const MAX_GREETING_VIDEO_DURATION_SECONDS = 60;
export const MAX_PUSH_TITLE_CHARS = 100;
export const MAX_PUSH_BODY_CHARS = 255;
export const RECIPE_TAGS = [
    {index: 0, description: "Breakfast"},
    {index: 1, description: "Dinner"},
    {index: 2, description: "Lunch"},
    {index: 3, description: "Snack"},
    {index: 4, description: "Vegan"},
    {index: 5, description: "Vegetarian"},
    {index: 6, description: "Weight loss"},
    {index: 7, description: "Weight gain"},
    {index: 8, description: "Desserts"}]
export const CLOUD_URL = "https://d3nf3776sv3rpv.cloudfront.net/";

