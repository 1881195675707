import React, { useState, useRef, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';
import createRecipeIcon from 'src/Assets/new/createRecipeIcon.png';
import BackButton from 'src/Elements/BackButton';
import WhiteTextField from 'src/Elements/WhiteTextField';
import WhiteButton from 'src/Elements/WhiteButton';
import BlueTransparentButton from 'src/Elements/BlueTransparentButton';
import { Container } from '@material-ui/core';
import { MAX_INGREDIENTS_IN_RECIPE, MAX_COOKING_METHODS_IN_RECIPE, RECIPE_TAGS } from 'src/contants';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@material-ui/core/IconButton';
import BlueButton from 'src/Elements/BlueButton';
import BlueBorderButton from 'src/Elements/BlueBorderButton';
import ImagePreview from 'src/Elements/ImagePreview';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import Drawer from '@material-ui/core/Drawer';
import Backdrop from '@material-ui/core/Backdrop';
import CookingMethods from './CookingMethods';

const EditAddRecipe = ({ recipe, openCategory, foodCategories, onClose, setLoadingText }) => {

    const StateEnum = {
        ADD: 0,
        EDIT: 1
    };

    const classes = useStyles();
    const inputFileRef = useRef();
    const [preview, setPreview] = useState('');
    const [image, setImage] = useState(null);
    const [recipeName, setRecipeName] = useState('');
    const [recipeDescription, setRecipeDescription] = useState('');
    const [recipeTime, setRecipeTime] = useState('');
    const [localRecipe, setLocalRecipe] = useState(null);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const [recipeState, setRecipeState] = useState(StateEnum.ADD);

    useEffect(() => {
        if (recipe == null) {
            setRecipeState(StateEnum.ADD);
            setLocalRecipe({
                name: '',
                description: '',
                foodcategoryid: openCategory ? openCategory.id : 0,
                image: '',
                time: '10',
                carbs: "10",
                fat: "10",
                protein: "10",
                calories: "10",
                ingredients: [],
                methods: [],
                tags: []
            });
        } else {
            setRecipeState(StateEnum.EDIT);
            setRecipeName(recipe.name);
            setRecipeTime(recipe.time)
            setRecipeDescription(recipe.description)
            setLocalRecipe(recipe);
            setPreview(recipe.image);
        }
    }, [recipe]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
            setImage(file);
            setPreview(URL.createObjectURL(file));
        } else {
            setImage(null);
            setPreview(null);
        }
    };

    const handleUploadImage = () => {
        inputFileRef.current.click();
    };

    const addNewIngredient = () => {
        const newIngredient = {
            index: localRecipe.ingredients.length,
            name: '',
            amount: '',
        };

        setLocalRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: [...prevRecipe.ingredients, newIngredient],
        }));
    };

    const deleteIngredient = (index) => {
        const updatedIngredients = [...localRecipe.ingredients];
        updatedIngredients.splice(index, 1);

        setLocalRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: updatedIngredients,
        }));
    };

    const onTagAdded = (newTag) => {
        if (!localRecipe.tags.some(tag => tag.index === newTag.index)) {
            const updatedTags = [...localRecipe.tags, newTag];
            setLocalRecipe({ ...localRecipe, tags: updatedTags });
        }
    }

    const onTagRemoved = (tagToRemove) => {
        const updatedTags = localRecipe.tags.filter(tag => tag.index !== tagToRemove.index);
        setLocalRecipe({ ...localRecipe, tags: updatedTags });
    }

    const onFoodCategoryAdded = (foodCategoryId) => {
        setLocalRecipe({ ...localRecipe, foodcategoryid: foodCategoryId });
    }

    const onFoodCategoryRemoved = () => {
        const { foodcategoryId, ...restOfRecipe } = recipe;
        setLocalRecipe(restOfRecipe);
    }

    const onDoneRecipeClick = async () => {

        try {
            const previousImage = localRecipe.image
            if (recipeState == StateEnum.EDIT && previousImage && image && previousImage != image) {
                setLoadingText("Deleting previous image")
                const result = await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, previousImage)
            }

            let imageUrl = localRecipe.image;
            if (image != null) {
                setLoadingText("Uploading image")
                imageUrl = await NetworkProvider.upload_image(loginCredentials, setLoginCredentials, image);
            }

            if (recipeState == StateEnum.EDIT) {
                setLoadingText("Updating recipe")
            } else {
                setLoadingText("Adding recipe")
            }

            const networkRecipe = {
                ...localRecipe,
                image: imageUrl ?? '',
                name: recipeName ?? '',
                description: recipeDescription ?? '',
                time: recipeTime ?? '0'
            };

            let recipeData;
            if (recipeState == StateEnum.EDIT) {
                recipeData = await NetworkProvider.update_recipe(loginCredentials, setLoginCredentials, networkRecipe);
            } else {
                recipeData = await NetworkProvider.add_recipe(loginCredentials, setLoginCredentials, networkRecipe);
            }
            const recipes = CategoriesReducer.sortedRecipes([recipeData]);
            onClose(recipes[0])
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            window.alert(error.message);
        }
    }

    const onDoneClick = async (newMethods, isDone) => {
        const networkRecipe = {
            ...localRecipe,
            methods: newMethods
        };

        let recipeData;
        if (isDone) {
            setLoadingText("Updating methods");
        }

        try {
            if (recipeState == StateEnum.EDIT || localRecipe.id) {
                recipeData = await NetworkProvider.update_recipe(loginCredentials, setLoginCredentials, networkRecipe);
            } else {
                recipeData = await NetworkProvider.add_recipe(loginCredentials, setLoginCredentials, networkRecipe);
                setRecipeState(StateEnum.EDIT)
            }
            const recipes = CategoriesReducer.sortedRecipes([recipeData]);
            setLocalRecipe(recipes[0])

            if (isDone) {
                setLoadingText(null);
                setIsSideMenuOpen(false)
            }
        } catch (error) {
            alert(error);
            setLoadingText(null);
        }
    }

    const titledTextField = (title, value, placeholder, onChange, multiline = false, rows = 1) => {
        return (
            <div className={classes.textFieldContainer}>
                <div className={classes.textFieldTitle}>{title}</div>
                <WhiteTextField
                    fullWidth
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => onChange(e.target.value)}
                    multiline={multiline}
                    rows={rows}
                />
            </div>
        )
    }

    const nutritionBarContent = () => {
        if (!localRecipe) {
            return (<></>)
        }
        const textFieldTitles = ["Protein", "Fat", "Carbs", "Calories"];
        const nutritionValues = [
            localRecipe.protein,
            localRecipe.fat,
            localRecipe.carbs,
            localRecipe.calories,
        ];

        const textFields = [];

        for (let i = 0; i < textFieldTitles.length; i++) {
            textFields.push(
                <div key={i} className={classes.nutritionBar}>
                    {titledTextField(
                        textFieldTitles[i],
                        nutritionValues[i],
                        "0",
                        (value) => {
                            const updatedRecipe = { ...localRecipe };
                            updatedRecipe[textFieldTitles[i].toLowerCase()] = String(value);
                            setLocalRecipe(updatedRecipe);
                        }
                    )}
                </div>
            );
        }

        return textFields;
    };

    const ingredientsContent = () => {
        if (localRecipe == null) {
            return <></>;
        }

        return localRecipe.ingredients.map((ingredient, index) => (
            <div key={ingredient.id} className={classes.ingredientsContainer}>
                <div className={classes.ingredientFields}>
                    {titledTextField('Amount', ingredient.amount, 'Enter amount', (value) => {
                        const updatedIngredients = [...localRecipe.ingredients];
                        updatedIngredients[index].amount = value.toString();

                        setLocalRecipe((prevRecipe) => ({
                            ...prevRecipe,
                            ingredients: updatedIngredients,
                        }));
                    })}
                    <div className={classes.horizontalSpacer}></div>
                    <div className={classes.ingredientNameContainer}>
                        {titledTextField('Ingredient Name', ingredient.name, 'Enter ingredient name', (value) => {
                            const updatedIngredients = [...localRecipe.ingredients];
                            updatedIngredients[index].name = value;

                            setLocalRecipe((prevRecipe) => ({
                                ...prevRecipe,
                                ingredients: updatedIngredients,
                            }));
                        })}
                        <IconButton
                            onClick={() => deleteIngredient(index)}
                            className={classes.deleteIcon}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        ));
    };

    const cookingMethodsDrawerContent = () => {

        if (!localRecipe)
            return null;

        return <>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="right"
                open={isSideMenuOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <CookingMethods
                    recipe={localRecipe}
                    setRecipe={setLocalRecipe}
                    onDoneClick={onDoneClick}
                    setLoadingText={setLoadingText}
                />
            </Drawer>
            <Backdrop
                className={classes.backdrop}
                open={isSideMenuOpen}
                onClick={() => setIsSideMenuOpen(false)}
            />
        </>
    }

    const tagsContent = () => {
        if (!localRecipe) {
            return <></>;
        }
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {RECIPE_TAGS.map((tag, index) => (
                    <div key={index} style={{ margin: '5px' }}>
                        {localRecipe.tags.some(t => t.index === tag.index) ? (
                            <WhiteButton onClick={() => onTagRemoved(tag)}>
                                {tag.description}
                            </WhiteButton>
                        ) : (
                            <BlueBorderButton onClick={() => onTagAdded(tag)}>
                                + {tag.description}
                            </BlueBorderButton>
                        )}
                    </div>
                ))}
            </div>
        );
    }

    const foodCategoriesContent = () => {
        if (!localRecipe) {
            return <></>;
        }
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {foodCategories.map((category, index) => (
                    <div key={index} style={{ margin: '5px' }}>
                        {localRecipe.foodcategoryid === category.id ? (
                            <WhiteButton onClick={() => onFoodCategoryRemoved()}>
                                {category.title}
                            </WhiteButton>
                        ) : (
                            <BlueBorderButton onClick={() => onFoodCategoryAdded(category.id)}>
                                {category.title}
                            </BlueBorderButton>
                        )}
                    </div>
                ))}
            </div>
        );
    }

    const RecipeContent = () => {
        const canAddIngredient = localRecipe?.ingredients?.length < MAX_INGREDIENTS_IN_RECIPE;
        const canAddCookingMethod = localRecipe?.methods?.length < MAX_COOKING_METHODS_IN_RECIPE;

        return (
            <>
                {titledTextField("Recipe name", recipeName, "Enter recipe name", setRecipeName)}
                <div className={classes.descriptionContainer}>
                    {titledTextField("Recipe Description (serving size or other details)",
                        recipeDescription, "Enter recipe description", setRecipeDescription, true, 7
                    )}
                </div>
                <div className={classes.preparationTimeContainer}>
                    {titledTextField("Preparation time (minutes)",
                        recipeTime, "Enter recipe preparation time in minutes", setRecipeTime, true, 1
                    )}
                </div>
                <Container className={classes.imageUploadContainer}>
                    {preview && (
                        <ImagePreview
                            src={preview}
                            alt="preview"
                            className={classes.imagePreview}
                        />
                    )}
                    <p className={classes.imageUploadTitle}>{recipeName}</p>
                    <div className={classes.uploadButtonContainer}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            ref={inputFileRef}
                            style={{ display: 'none' }}
                        />
                        <WhiteButton onClick={handleUploadImage}>Upload</WhiteButton>
                    </div>
                </Container>
                <div className={classes.separator}></div>
                <div className={classes.nutritionBarContainer}>
                    {nutritionBarContent()}
                </div>
                <div className={classes.separator}></div>
                <div className={classes.ingredientsContainer}>
                    <div className={classes.textFieldTitle}>{"Ingredients"}</div>
                    {ingredientsContent()}
                    {canAddIngredient && (
                        <div className={classes.ingredientsButtonContainer}>
                            <BlueTransparentButton onClick={addNewIngredient}> Add ingredient</BlueTransparentButton>
                        </div>
                    )}
                </div>
                <div className={classes.separator}></div>
                <div className={classes.cookingMethodContainer}>
                    <BlueTransparentButton onClick={() => setIsSideMenuOpen(true)}> Open cooking instructions</BlueTransparentButton>
                </div>
                <div className={classes.separator}></div>
                <div className={classes.tagsContainer}>
                    <div className={classes.textFieldTitle}>{"Tags"}</div>
                    <div className={classes.subtitle}>{"Set tags to identify the recipe when using search"}</div>
                    {tagsContent()}
                </div>

                <div className={classes.separator}></div>
                <div className={classes.tagsContainer}>
                    <div className={classes.textFieldTitle}>{"Food category"}</div>
                    <div className={classes.subtitle}>{"Set food category to group recipes in app"}</div>
                    {foodCategoriesContent()}
                </div>
                <div className={classes.separator}></div>
                <div className={classes.addButtonContainer}>
                    <BlueButton
                        onClick={onDoneRecipeClick}
                        style={{ width: '200px' }}
                    >
                        {recipeState == StateEnum.ADD ? "Add" : "Update"}
                    </BlueButton>
                </div>
                {cookingMethodsDrawerContent()}
            </>
        );
    };


    return (
        <div>
            <BackButton onClick={() => onClose()} />
            <div className={classes.contentArea}>
                <div className={classes.recipeContent}>
                    {RecipeContent()}
                </div>
                {/*<div className={classes.exampleContainer}>
                    <h3 className={classes.exampleTitle}>Example recipes</h3>
                    <img src={createRecipeIcon} alt="Example recipes" className={classes.categoriesExampleIcon} />
    </div>*/}
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        backgroundColor: 'transparent'
    },
    recipeContent: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: '20px'
    },
    exampleContainer: {
        textAlign: 'center',
    },
    exampleTitle: {
        fontSize: 13,
        fontFamily: 'Inter',
        fontWeight: 500,
        marginBottom: 5,
        color: '#736F6F',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    textFieldWrapper: {
        width: '100%'
    },
    imageUploadContainer: {
        marginTop: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: 20
    },
    imagePreview: {
        width: 113,
        height: 113,
        borderRadius: '8px',
    },
    imageUploadTitle: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        color: '#16192C',
    },
    uploadButtonContainer: {
        width: '100px'
    },
    textFieldTitle: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
        color: '#425466',
        marginBottom: '10px'
    },
    subtitle: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '14px',
        color: '#737373',
        marginBottom: '10px'
    },
    nutritionBarContainer: {
        marginTop: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    nutritionBar: {
        flex: 1,
        marginRight: '20px',
    },
    ingredientsContainer: {
        marginTop: '20px',
    },
    ingredientFields: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    horizontalSpacer: {
        width: '20px'
    },
    verticalSpacer: {
        height: '20px'
    },
    ingredientsButtonContainer: {
        marginTop: '20px',
        marginBottom: '20px',
        width: '160px',
    },
    deleteIcon: {
        color: '#DF2E2E',
        marginTop: '30px',
        marginLeft: '5px'
    },
    ingredientNameContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    separator: {
        width: '100%',
        borderTop: '1px solid #E6E6E6',
        marginBottom: '20px',
    },
    descriptionContainer: {
        marginTop: 30,
        width: '100%',
    },
    preparationTimeContainer: {
        marginTop: 30,
        width: '30%',
        minWidth: '350px'
    },
    cookingMethodsButtonContainer: {
        marginTop: '20px',
        marginBottom: '20px',
        width: '200px',
    },
    cookingMethodContainer: {
        backgroundColor: 'transparent',
        paddingBottom: 20
    },
    tagsContainer: {
        backgroundColor: 'transparent'
    },
    addButtonContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '200px',
    },
    drawer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        flexShrink: 0,
    },
    drawerPaper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        backgroundColor: '#E4E9ED'
    },
    backdrop: {
        zIndex: 1000,
        color: '#fff',
    },
}));

export default EditAddRecipe;
