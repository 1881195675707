import React, { useState, useEffect, useContext } from 'react';
import { NetworkProvider } from 'src/NetworkProvider';
import { makeStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_PK_KEY } from 'src/contants';
import CompanyDetailsForm from 'src/Components/Onboarding/CompanySetup/CompanyDetailsForm/CompanyDetailsForm';
import { AuthContext } from 'src/AuthProvider';
import { getNameFromAuthorData, getSurnameFromAuthorData } from 'src/Utilities/Utilities';

import colors from 'src/colors.json';
import trainers from 'src/trainers.json';

function MyCompanyInfo({ authorData, userData, setLoadingText, trainer }) {
  const [companyDetails, setCompanyDetails] = useState(null);
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
  const classes = useStyles();

  let trainerColors;
    let trainerInfo;
    let stripeKey = STRIPE_PK_KEY;

    if (trainer) {
        trainerColors = colors[trainer] || colors['default']
        trainerInfo = trainers[trainer]
        stripeKey = trainerInfo["stripe_public_key"]
    }

    const stripePromise = loadStripe(stripeKey, { locale: 'en' });

  useEffect(() => {
    fillCompanyDetails();
  }, []);

  const fillCompanyDetails = async () => {
    try {
      let data;
      if (trainer) {
        data = await NetworkProvider.fetch_user_billing_details(loginCredentials, setLoginCredentials, trainer);
      } else {
        data = await NetworkProvider.fetch_billing_details(loginCredentials, setLoginCredentials);
      }
      setCompanyDetails(data);
    } catch (error) {
      window.alert(error);
    }
  };

  const companyDetailsReady = async (companyDetails) => {
    try {
      setLoadingText("Updating details")
      if (trainer) {
        await NetworkProvider.update_user_billing_details(loginCredentials, setLoginCredentials, companyDetails, trainer);
      } else {
        await NetworkProvider.update_billing_details(loginCredentials, setLoginCredentials, companyDetails);
      }
      setLoadingText(null)
      window.alert("Details updated");
    } catch (error) {
      setLoadingText(null)
      window.alert(error);
    }
  }

  return (
    <div className={classes.content}>
      <h3 style={{color: trainerColors?.textPrimaryColor}}>Adjust details</h3>
      <Elements stripe={stripePromise}>
        <CompanyDetailsForm
          onCompanyDetailsReady={companyDetailsReady}
          initialDetails={companyDetails}
          submitTitle={"Update details"}
          requirePayment={false}
          editName={true}
          trainerColors={trainerColors}
          name={getNameFromAuthorData(userData || authorData)}
          surname={getSurnameFromAuthorData( userData || authorData)}
          email={userData?.mail || authorData?.mail}
        />
      </Elements>
    </div>
  );
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%'
  }
});

export default MyCompanyInfo